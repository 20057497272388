import React from 'react';

const Loader = () => {
  const separator = require('../assets/images/icons/reception.svg').default;

  return (
    <div className="loader">
      <img src={separator} alt="loader" />
    </div>
  );
};

export default Loader;
