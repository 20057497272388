import React from 'react';
import Title from '../components/Title';

const Rundown = ({ data }) => {
  return (
    <div
      className="card col-md-12 d-flex flex-column justify-content-center align-items-center"
      data-aos={'zoom-in-right'}
      data-aos-duration="1500">
    <div className="rundown container-fluid">
      <Title title="Rundown Acara" />
      <ul className="list-group list-group-flush">
        {data.map((item, index) => (
          <li key={index} className="list-group-item">{item.time} : {item.activity}</li>
        ))}
      </ul>
    </div>
    </div>
  );
};

export default Rundown;