export const data = {
  en: {
    config: {
      name: 'PT. SANTINILESTARI CAKRA SENTOSA',
      bgm: 'https://ia802805.us.archive.org/4/items/MarryYourDaughter_525/BrianMcknight-MarryYourDaughterlyrics.mp3',
    },
    header: {
      bgImageLandscape: [require('./assets/images/bg-1.jpeg'), require('./assets/images/bg-2.jpeg'), require('./assets/images/bg-3.jpg')],
      bgImagePortrait: [require('./assets/images/bg-1.jpeg'), require('./assets/images/bg-2.jpeg'), require('./assets/images/bg-3.jpg')],
      bridegroom: 'SYUKURAN PEMBUKAAN',
      desc: 'PT. SANTINILESTARI CAKRA SENTOSA',
      date: 'Senin, 7 Oktober 2024',
      timestamp: new Date('2024-10-07').getTime(),
      ongoing: 'BERLANGSUNG',
      days: 'HARI',
      hours: 'JAM',
      minutes: 'MENIT',
      seconds: 'DETIK',
      line1: 'Mohon kirimkan Kepada: Bapak / Ibu / Saudara',
      line2: 'Kami Mengundang Anda Untuk Merayakan Syukuran Pembukaan Kami',
      button: 'Buka Undangan',
    },
    navbar: {
      brand: 'SCS',
      nav: [
        { to: 'header', text: 'Beranda' },
        { to: 'events', text: 'Acara' },
        { to: 'location', text: 'Lokasi' },
      ],
    },
    events: {
      reception: {
        type: 'reception',
        foto: require('./assets/images/events/reception.jpg'),
        title: 'ACARA',
        date: 'Senin, 7 Oktober 2024',
        time: 'Bertempat di :',
        address: {
          line1: 'PT. SANTINILESTARI CAKRA SENTOSA',
          line2: 'Jl. Soekarno Hatta No.2/210, Labuhan Ratu, raya',
          line3: 'Kota Bandar Lampung, Lampung 35141',
        },
      },
    },
    location: {
      maps: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.3528333088952!2d105.2587872!3d-5.3630268999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e40c5003036131d%3A0x122610656a29f071!2sPT.%20SANTINILESTARI%20CAKRA%20SENTOSA!5e0!3m2!1sen!2sid!4v1726723279748!5m2!1sen!2sid',
    },
    rundown: [
      { time: '16.00 - 16.05', activity: 'Pembukaan' },
      { time: '16.05 - 16.30', activity: 'Sambutan Para Pemegang Saham' },
      { time: '16.30 - 16.45', activity: 'Sambutan Jajaran Direksi' },
      { time: '16.45 - 17.00', activity: 'Pembacaan Doa' },
      { time: '17.00 - 17.10', activity: 'Pemotongan Tumpeng' },
      { time: '17.10 - selesai', activity: 'Makan bersama' },
    ]
  },
};
